import { singleTextEllipsis } from '@byecode/ui'
import type { TextBlockAbstract, TextProtocol } from '@lighthouse/core'
import { BACKGROUND_TYPE } from '@lighthouse/core'
import type { RichTextEditorProps } from '@lighthouse/shared'
import {
    APP_MAIN_COLOR_VARIABLE,
    applyFillPickerValue4CssColorValue,
    getGradientCssText,
    getTextColorStyle,
    RichtextPureRender,
    TEXT_FONT_NORMAL,
    useFillPickerContext,
    usePageContainer
} from '@lighthouse/shared'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

const TEXT_PROTOCOL_SET = new Set<string>(['lineClamp'] satisfies (keyof TextProtocol)[])

const TextContainer = styled.div.withConfig<TextProtocol>({
    shouldForwardProp: p => !TEXT_PROTOCOL_SET.has(p)
})`
    display: flex;
    flex-direction: column;
    margin: var(--block-padding);

    .ProseMirror,
    .ProseMirror > * {
        ${({ lineClamp }) => {
            if (!lineClamp) {
                return
            }
            return lineClamp === 1
                ? css`
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: pre;
                  `
                : css`
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: ${lineClamp};
                      overflow: hidden;
                  `
        }}
    }
`

interface RichTextBlockProps {
    config?: RichTextEditorProps['config']
    blockData: TextBlockAbstract
    onChange?: (value: TextBlockAbstract) => void
}

const TextBlock: React.FC<RichTextBlockProps> = ({ config: richtextConfig, blockData, onChange }) => {
    const {
        config: {
            content: { value },

            font,

            color,
            border,
            shadow
        }
    } = blockData
    const { align, fontFamily, fontSize, lineHeight, letterSpacing, lineClamp } = font

    const { endpoint } = usePageContainer()
    const { palettes } = useFillPickerContext()

    const borderStyle = useMemo(() => {
        if (!border || !border.color) {
            return
        }

        const borderColor = applyFillPickerValue4CssColorValue(border.color, palettes)

        return { WebkitTextStrokeColor: borderColor, WebkitTextStrokeWidth: border.all } satisfies React.CSSProperties
    }, [border, palettes])

    const shadowStyle = useMemo(() => {
        if (!shadow || !shadow.color) {
            return
        }

        const shadowColor = applyFillPickerValue4CssColorValue(shadow.color, palettes)

        return { textShadow: `${shadow.x || 0}px ${shadow.y || 0}px ${shadow.blur || 0}px ${shadowColor}` } satisfies React.CSSProperties
    }, [palettes, shadow])

    const fontStyle = useMemo(() => {
        const rawFontSize = fontSize ?? 1
        const finalFontSize = endpoint === 'xs' ? Math.min(3.2, rawFontSize) : rawFontSize
        return {
            fontFamily: fontFamily === TEXT_FONT_NORMAL ? undefined : fontFamily,
            fontSize: `${finalFontSize}em`,
            letterSpacing: `${letterSpacing ?? 0}rem`,
            lineHeight: `${lineHeight ?? 1}`
        }
    }, [endpoint, fontFamily, fontSize, letterSpacing, lineHeight])

    const styles = useMemo(
        () => ({
            editorContent: {
                textAlign: align,
                ...getTextColorStyle(color, palettes),
                ...borderStyle,
                ...shadowStyle,
                ...fontStyle
            }
        }),
        [align, borderStyle, color, fontStyle, palettes, shadowStyle]
    )

    return (
        <TextContainer lineClamp={lineClamp}>
            <RichtextPureRender styles={styles} value={value} config={richtextConfig} />
            {/* <RichTextEditor readonly styles={styles} value={value} config={richtextConfig} data-extra-text-restrict="1" /> */}
        </TextContainer>
    )
}

export default TextBlock
